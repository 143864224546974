<template>
  <div
    class="item-container flex flex-col border-gray border-solid relative w-[auto] h-[320px] p-5 text-green-swamp hover:text-orange"
  >
    <div
      class="flex pb-[17px] mb-[15px] space-x-[15px] border-gray border-solid border-b hover:cursor-pointer"
      @click="
        navigateTo(
          useLocalePath()(
            ProductVariantEntity.buildProductLink(product, variant, '', locale)
          )
        )
      "
    >
      <div class="min-w-[74px] h-[74px] flex justify-center items-center">
        <nuxt-img
          v-if="variant?.images?.length && !imageError"
          :src="variant?.images[0].path"
          class="w-full h-auto max-h-[70px] max-w-[90px] object-contain"
          format="webp"
          width="74"
          height="74"
          loading="lazy"
          @error="() => (imageError = true)"
        />
        <nuxt-img
          v-else
          :src="NO_IMAGE_PATH"
          class="w-full h-auto"
          width="74"
          height="30"
          format="webp"
        />
        <!-- <div class="swiper-lazy-preloader"></div> -->
      </div>
      <div class="space-y-[11px]">
        <div class="text-xs text-green-swamp">
          {{ t('Вiдгук про товар') }}
        </div>
        <div class="text-[15px] font-bold line-clamp-2">
          {{ variant?.name }}
        </div>
      </div>
    </div>

    <div class="space-y-2.5 flex flex-col grow">
      <AppReviewRating :rating="props.data.rating" />

      <div class="text-green-swamp text-[15px] font-bold">
        {{ props.data?.author?.firstName }}
      </div>

      <div class="grow">
        <div class="text-sm text-green-swamp line-clamp-4">
          {{ props.data?.comment }}
        </div>
      </div>

      <div
        class="text-xs link-primary-underline mt-auto"
        @click="
          navigateTo(
            useLocalePath()(
              ProductVariantEntity.buildProductLink(
                product,
                variant,
                '#reviews',
                locale
              )
            )
          )
        "
      >
        {{ t('Перейти до вiдгуків') }}
      </div>
    </div>
  </div>
</template>

<script setup>
import ProductEntity from '~/entities/Product.entity'
import ProductVariantEntity from '~/entities/ProductVariant.entity'

const { t, locale } = useI18n()
const imageError = ref(false)

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
})
const product = computed(() => new ProductEntity(props.data?.reviewSubject))

const variant = computed(
  () => product.value?.getVariant && product.value?.getVariant()
)
</script>

<style scoped>
.item-container:hover {
  box-shadow: 0 10px 40px rgba(38, 38, 38, 0.05),
    0 2px 4px rgba(38, 38, 38, 0.02), 0 10px 40px rgba(38, 38, 38, 0.05),
    0 2px 4px rgba(38, 38, 38, 0.02);
  background: #ffffff;
}
</style>
