import ProductFeedback from '~/entities/ProductFeedback.entity'
export const useReviewsStore = defineStore({
  id: 'reviews-store',
  state: () => {
    return {
      list: []
    }
  },
  actions: {
    async fetchReviews() {
      const res = await useApi().productReviews.getRecently()
      this.list = ProductFeedback.parseApiResponse(res)?.data
    }
  },
  getters: {
    reviewList: state => state?.list
  }
})
