<template>
  <ListHorizontal
    v-if="reviews?.length"
    :title="props.title"
    :sub-title="props.subTitle"
    :list-link="listLink"
    base-slide-width="380"
    :slides="reviews"
    :slides-per-view="1"
    :breakpoints="{
      900: {
        slidesPerView: 2
      },
      1200: {
        slidesPerView: 3
      },
      1500: {
        slidesPerView: 4
      }
    }"
  >
    <template #default="{ slide: review }">
      <Item class="first:border-y border-l last:border-r mb-5" :data="review" />
    </template>
  </ListHorizontal>
  <div v-else class="h-[370px] w-full animate-pulse"></div>
</template>

<script setup>
import Item from './Item.vue'
import { useReviewsStore } from '~/stores/reviews'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  subTitle: {
    type: String,
    default: ''
  },
  listLink: {
    type: Object,
    default: null
  }
})

const reviewStore = useReviewsStore()

const reviews = computed(() => reviewStore.reviewList || [])

useAsyncData('reviews-home', () => reviewStore.fetchReviews())
</script>
